// extracted by mini-css-extract-plugin
export var heroSection = "index-module--heroSection--b4b98";
export var heroSectionBg = "index-module--heroSectionBg--6a89d";
export var heroSectionBgOver = "index-module--heroSectionBgOver--19902";
export var spacer = "index-module--spacer--0fb74";
export var landingCta = "index-module--landingCta--b152d";
export var ctaAccent = "index-module--ctaAccent--43217";
export var profile = "index-module--profile--2f8f7";
export var hello = "index-module--hello--81edc";
export var name = "index-module--name--adab4";
export var description = "index-module--description--dc683";
export var contactLinks = "index-module--contactLinks--5882c";
export var contactLink = "index-module--contactLink--263e3";
export var profileCard = "index-module--profileCard--495fe";
export var profileImage = "index-module--profileImage--d1c94";
export var profileImageDummy = "index-module--profileImageDummy--2d66f";
export var amazonAlexaSection = "index-module--amazonAlexaSection--9c473";
export var donationSection = "index-module--donationSection--82a02";
export var hireMeSection = "index-module--hireMeSection--baf57";
export var creditSection = "index-module--creditSection--35a9c";
export var seeMoreButton = "index-module--seeMoreButton--81cca";
export var aboutSection = "index-module--aboutSection--98722";
export var aboutText = "index-module--aboutText--204a3";
export var skills = "index-module--skills--ca073";
export var skillList = "index-module--skillList--4681b";
export var skill = "index-module--skill--e74ad";
export var skill_framework = "index-module--skill_framework--f11c4";
export var skill_platform = "index-module--skill_platform--66a10";
export var skill_program = "index-module--skill_program--00e86";
export var skill_os = "index-module--skill_os--d4b8f";
export var skill_language = "index-module--skill_language--38150";
export var careerContainer = "index-module--careerContainer--e3e58";
export var birth = "index-module--birth--9b762";
export var mainline = "index-module--mainline--69a32";
export var entry = "index-module--entry--ce2be";
export var entryContent = "index-module--entryContent--dba58";
export var title = "index-module--title--56ee9";
export var date = "index-module--date--627d8";
export var entryTypeeducation = "index-module--entryTypeeducation--bcee0";
export var entryTypeapprenticeship = "index-module--entryTypeapprenticeship--cf586";
export var entryTypeinternship = "index-module--entryTypeinternship--9e9ef";